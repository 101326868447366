import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import HomecareTreatments from "./HomecareTreatments";
import Search from "./Search.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
// const searchRegExp = /<networkHospitalCount \/>/g
// const stringSearchRegExp = /<stringNetworkHospitalCount \/>/g
// const HOSPITALCOUNT = process.env.HOSPITALCOUNT;
// const STRHOSPITALCOUNT = process.env.STRHOSPITALCOUNT;

var apiUrl = `${process.env.API_URL}`;

const HEALTH_CHECKUP_PACKAGES = `health-checkup-packages`;

const createList = function(res, setTotalCount) {
  var responseList = res && (res.responseList ? res.responseList : []);
  var responseArr = [];
  responseList &&
    responseList.map(response => {
      var temp = [];
      var res_temp = {};
      switch (response.service_provider.toLowerCase()) {
        // case `aiwo`:
        //   res_temp = {
        //     name: `AIWO Health Center`,
        //     url: `/${HEALTH_CHECKUP_PACKAGES}-aiwo`
        //   };
        //   break;
        case `safeguard`:
          res_temp = {
            name: `Safeguard Family Health Private Limited`,
            url: `/${HEALTH_CHECKUP_PACKAGES}-safeguard-family-health-private-limited`
          };
          break;
        case `i2h`:
          res_temp = {
            name: `I2H`,
            url: `/${HEALTH_CHECKUP_PACKAGES}-i2h`
          };
          break;
        // case `1mg`:
        //   res_temp = {
        //     name: `1MG`,
        //     url: `/${HEALTH_CHECKUP_PACKAGES}-1mg`
        //   };
        //   break;
        case `neuberg`:
          res_temp = {
            name: `Neuberg Diagnostic Private Limited`,
            url: `/${HEALTH_CHECKUP_PACKAGES}-neuberg`
          };
          break;
        case `thyrocare`:
          res_temp = {
            name: `Thyrocare`,
            url: `/${HEALTH_CHECKUP_PACKAGES}-Thyrocare`
          };
          break;
        case `healthians`:
          res_temp = {
            name: `Healthians`,
            url: `/${HEALTH_CHECKUP_PACKAGES}-Healthians`
          };
          break;
        case `metropolis`:
            res_temp = {
              name: `Metropolis`,
              url: `/${HEALTH_CHECKUP_PACKAGES}-Metropolis`
            };
          break;
      }
      temp.push(res_temp.name);
      temp.push(response.city);
      temp.push(response.state);
      temp.push(response.pinCode);
      temp.push(
        <a target="_blank" rel="noopener noreferrer" href={res_temp.url}>
          View Package
        </a>
      );

      responseArr.push(temp);
    });
  return {
    responseArr: responseArr,
    responseList: responseList,
    setTotalCount: setTotalCount
  };
};

const PHCSdoorstep = ({ classes, data }) => {
  const searchOptions = {
    sort: false,
    serverSide: true
  };
  var Images = data.allStrapiImages.edges;
  var bwImage = Images[0];
  var clrImage = Images[1];

  return (
    <Layout
      image1={
        bwImage &&
        bwImage.node &&
        bwImage.node.content &&
        bwImage.node.content.childImageSharp.fluid
      }
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100 }}
      >
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem style={{ padding: `70px 0px` }}>
              <Search
            createList={createList}
            searchFor="preventive health checkup"
            title={"Preventive Health Check Services – Door Step"}
            apiUrl={`${apiUrl}checkup/centre`}
            noResultText="We do not have home pick up service at the requested location, kindly visit our diagnostic centre list"
            tableHeaders={[
              `Service Provider Name`,
              `Area/City`,
              `State`,
              `Pincode`,
              `Packages`
            ]}
            buttonData={{
              placeholder: `Search Pincode`,
              cta: `SEARCH HOME PICKUP CENTRES`
            }}
            searchOptions={searchOptions}
          />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
PHCSdoorstep.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object
};
export default withStyles(customStaticPageStyle)(PHCSdoorstep);

export const PHCSdoorstepQuery = graphql`
  query PHCSdoorstep {
    allStrapiSections(
      filter: { category: { eq: "network_hospital" } }
      sort: { fields: sequence, order: ASC }
    ) {
      edges {
        node {
          title
          summary
          strapiId
          icon {
            publicURL
          }
        }
      }
    }
    allStrapiImages(filter: { section: { category: { eq: "locate_us" } } }) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
